import React from "react";
import Logo from "../images/logo";
import "./footer.scss";

export default function Footer(props) {
  return (
    <footer className="row bg-tertiary justify-content-center">
      <div className="col-xs-12 d-flex flex-wrap justify-content-center align-items-center">
        <Logo className="mx-5 my-4" />
        <h2 className="text-white d-inline-block mx-auto">contact@stillsoftware.com</h2>
      </div>
      <div className="col-xs-12 d-flex align-items-center">
        <p className="mx-5">
          Still Software Ltd. is a limited company registered in Scotland <br />
          Registration Number SC476137<br />
          VAT Number: 185 5559 64
        </p>
      </div>
    </footer>
  );
}
