import React from "react";
import Logo from "./../images/logo";
import { Link, NavLink } from "react-router-dom";
import "./navbar.scss";
import { fromEvent } from "rxjs";

export default function Navbar(props) {
  let navRef = React.createRef();

  let scrollEventListener = fromEvent(window, "scroll");
  scrollEventListener.subscribe((event) => {
    if (window.scrollY == 0) {
      navRef.current.classList.remove("bg-white");
      navRef.current.classList.remove("navbar-light");
      navRef.current.classList.add("bg-primary");
      navRef.current.classList.add("navbar-dark");      
    } else {
      navRef.current.classList.remove("bg-primary");
      navRef.current.classList.remove("navbar-dark"); 
      navRef.current.classList.add("bg-white");
      navRef.current.classList.add("navbar-light");  
    }
  });

  let link = function (props) {
    let className = props.className
      ? "nav-item " + props.className
      : "nav-item";

    return (
      <li className={className}>
        <a className="nav-link" href={props.href}>
          {props.children}
        </a>
      </li>
    );
  };

  return (
    <nav
      ref={navRef}
      className="row navbar navbar-expand-lg navbar-dark bg-primary sticky-top"
    >
      <Link to="/">
        <Logo />
      </Link>
      <span className="navbar-brand">Still Software</span>
      {/* <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button> */}
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          {/* <li className="nav-item">
            <Link to="/services" className="nav-link">
              services
            </Link>
          </li> */}
          {/* <li className="nav-item">
            <Link to="/services" className="nav-link">
              process
            </Link>
          </li>
          <NavLink to="/about" component={link}>
            about us
          </NavLink>
          <li className="nav-item">
            <Link to="/contact" className="nav-link">
              contact
            </Link>
          </li> */}
        </ul>
      </div>
    </nav>
  );
}
