import React from 'react';
import './App.scss';
import Navbar from './navigation/navbar';
import Home from './home/home';
import About from './about/about';
import Footer from './footer/footer';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import 'bootstrap/dist/js/bootstrap';


function App() {
  return (     
    <Router>      
      <Navbar />

      <Switch>
        <Route path='/about'>
          <About />
        </Route>
        <Route path='/'>
          <Home />
        </Route>
      </Switch>

      <Footer />
    
    </Router>
  );
}

export default App;