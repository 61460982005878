import React from "react";
import "./process-image.scss";

export default function ProcessImage(props) {
  return (
    <div id="container" className="d-none d-lg-block">
      <div className="gradient-overlay"></div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 737.51 95.11">
        <g>
          <circle class="cls-4" cx="32.37" cy="32.35" r="26.85" />
          <circle class="cls-1" cx="532.37" cy="35.35" r="32" />
          <text class="cls-2" transform="translate(0.26 91.82)">
            Project Brief
          </text>
        </g>
        <g>
          <circle class="cls-1" cx="32.5" cy="32.5" r="32" />
          <text class="cls-2" transform="translate(508.73 91.86)">
            Analysis
          </text>
        </g>
        <g>
          <line class="cls-1" x1="65" y1="33" x2="500" y2="35" />
          <line class="cls-1" x1="564" y1="35" x2="737.5" y2="38" />
        </g>
      </svg>
    </div>
  );
}
