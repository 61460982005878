import React from "react";
import "./hero.scss";

export default function Hero(props) {
  return (
    <div id="hero" className="row">
      <svg className="d-none d-sm-block"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1439.5 1080"
        id="hero-background"
      >
        <defs>
          <clipPath id="clip-path" transform="translate(-480.5)">
            <rect x="897" y="451" width="893.13" height="595.15" />
          </clipPath>
          <linearGradient
            id="background-gradient"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" className="start-gradient" />
            <stop offset="100%" className="end-gradient" />
          </linearGradient>
        </defs>
        <g id="background">
          <polygon
            fill="url(#background-gradient)"
            points="1439.5 0 0 788.5 249.6 1080 1439.5 1080 1439.5 0"
          />
          <g id="bird">
            <path
              d="M1108.39,714s8-17.25,9.53-21.35-2.58-14.95,3.6-25c8.62-14.09,29.4-14.57,32-14.57,14.93,0,32.85,16.21,36.87,14.65,2.87-1.12-7.28-37.56,16.73-64.47,24.75-27.74,41.2-29.45,58.7-36.19,27.27-10.51,25.65-5.09,81.12-34.44,37.75-20,63.44-66.35,63.44-66.35s-9.45,39.39-15.26,50.82-18,40.1-42.07,61.12c-31.4,27.36-51,34.09-50.69,34.17,6.56,1.64,43.92-3.5,75.41-9.48,33.47-6.35,56.82-14.89,70.75-21.46,15.48-7.3,17.66-7.86,30.94-19.56s26.32-32.15,26.32-32.15-5.84,22.91-20.69,42.23-28.26,26.33-43.25,35.14-59,30.05-71.1,36.62-28,17.93-34.36,22.53c-15.41,11.23-20.65,28.14-23.15,32.08s-7.59,30.49-11.8,39-12.16,13.29-18,15.79-17.5,2.9-17.63,4.34,9.81,28.15,27,44.84,28.18,18.91,43.95,24.56,59.24,8.61,69.28,8.12c55.06.2,98.1-18,98.1-18s-27.1,18.76-96.17,24.15c-18.18,1.41-47.58,2-54.83,5.59s-4.72,12.38,9.48,22a168.83,168.83,0,0,0,41.9,20.55c21.23,7.71,30,10,46.75,13,20.69,3.43,56.57,1.82,56.57,1.82s-20.64,5.28-57.12,1.73-74-16.65-87.94-26.64-15.59-13.14-49.89-33.9-42.9-18.64-58.78-24.87a263.57,263.57,0,0,1-44.9-22.33c-6.83-4.33-19.66-15.4-24.13-22s-13.22-20.16-15.85-31.07-8.37-18.65-13.62-25.09-11.68-13-16.41-15.06-13.31-4-16.73-2.68-14.31,11.79-14.31,11.79"
              transform="translate(-480.5)"
            />
            <path
              d="M1790.13,994.44c0,28.56-199.93,51.71-446.57,51.71S897,1023,897,994.44s199.93-51.72,446.56-51.72,446.57,23.16,446.57,51.72m-446.57-51.72c-213.7,0-386.94,20.06-386.94,44.81s173.24,44.81,386.94,44.81,387-20.06,387-44.81-173.24-44.81-387-44.81"
              transform="translate(-480.5)"
            />
            <path
              d="M1690.24,986.18c0,22.18-155.21,40.15-346.68,40.15s-346.67-18-346.67-40.15S1152.1,946,1343.56,946s346.68,18,346.68,40.14M1343.56,946c-165.9,0-300.38,15.57-300.38,34.78s134.48,34.79,300.38,34.79S1644,1000,1644,980.82,1509.47,946,1343.56,946"
              transform="translate(-480.5)"
            />
            <path
              d="M1607,979.69c0,16.85-118,30.5-263.44,30.5s-263.43-13.65-263.43-30.5,117.94-30.51,263.43-30.51S1607,962.84,1607,979.69m-263.44-30.51c-126.06,0-228.26,11.84-228.26,26.43s102.2,26.44,228.26,26.44,228.27-11.84,228.27-26.44-102.2-26.43-228.27-26.43"
              transform="translate(-480.5)"
            />
          </g>
        </g>
      </svg>
      <div id="hero-content">
        <div>
          <h1>{props.h1}</h1>
          <h2>{props.h2}</h2>
        </div>
      </div>
      <div className="clear"></div>
    </div>
  );
}
