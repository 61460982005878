import React from "react";

export default function Logo(props) {

  let classes = props.className?.split(' ') ?? [];
  classes.push('logo');
    
  const className = classes.join(' ');

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 608 408.61" className={className}>
      <path
        id="OuterRipple"
        d="M608,370c0,19.44-136.11,35.2-304,35.2S0,389.39,0,370s136.11-35.21,304-35.21S608,350.5,608,370ZM304,334.74c-145.48,0-263.41,13.66-263.41,30.51s117.93,30.5,263.41,30.5,263.41-13.66,263.41-30.5S449.48,334.74,304,334.74Z"
        transform="translate(0 3.46)"
      />
      <path
        id="CenterRipple"
        d="M540,364.33c0,15.09-105.66,27.33-236,27.33S68,379.42,68,364.33,173.66,337,304,337,540,349.23,540,364.33ZM304,337c-112.94,0-204.49,10.6-204.49,23.68S191.06,384.36,304,384.36s204.49-10.6,204.49-23.68S416.94,337,304,337Z"
        transform="translate(0 3.46)"
      />
      <path
        id="InnerRipple"
        d="M483.33,359.91c0,11.47-80.29,20.76-179.33,20.76s-179.33-9.29-179.33-20.76S205,339.14,304,339.14,483.33,348.44,483.33,359.91ZM304,339.14c-85.82,0-155.39,8.06-155.39,18s69.57,18,155.39,18,155.39-8.06,155.39-18S389.82,339.14,304,339.14Z"
        transform="translate(0 3.46)"
      />
      <path
        id="Bird"
        d="M143.91,179s5.44-11.74,6.48-14.53-1.75-10.18,2.45-17.05c5.87-9.59,20-9.92,21.8-9.92,10.17,0,22.36,11,25.1,10,1.95-.76-5-25.57,11.39-43.89C228,84.73,239.18,83.57,251.09,79c18.56-7.15,17.46-3.47,55.22-23.45C332,41.94,349.5,10.37,349.5,10.37S343.06,37.18,339.11,45s-12.23,27.3-28.64,41.6C289.1,105.2,275.73,109.78,276,109.84c4.47,1.11,29.9-2.39,51.34-6.46,22.78-4.32,38.68-10.14,48.16-14.61,10.54-5,12-5.35,21.06-13.31s17.92-21.89,17.92-21.89-4,15.6-14.08,28.75-19.24,17.92-29.44,23.92-40.17,20.46-48.4,24.93-19.1,12.2-23.4,15.33c-10.48,7.65-14.05,19.16-15.75,21.84s-5.18,20.76-8,26.57-8.28,9.05-12.22,10.75-11.91,2-12,3,6.67,19.16,18.39,30.52,19.19,12.88,29.92,16.72,40.33,5.86,47.16,5.53c37.49.13,66.79-12.27,66.79-12.27s-18.45,12.77-65.47,16.43c-12.38,1-32.39,1.37-37.33,3.81s-3.21,8.43,6.46,15a115.12,115.12,0,0,0,28.52,14c14.45,5.25,20.45,6.83,31.82,8.83,14.09,2.33,38.51,1.23,38.51,1.23S401.83,312,377,309.56s-50.39-11.34-59.86-18.14-10.62-8.95-34-23.08-29.2-12.69-40-16.93a179.23,179.23,0,0,1-30.57-15.2c-4.65-3-13.38-10.49-16.42-15s-9-13.72-10.79-21.15-5.7-12.7-9.28-17.08-7.95-8.82-11.17-10.25-9.06-2.72-11.38-1.83-9.75,8-9.75,8"
        transform="translate(0 3.46)"
      />
    </svg>
  );
}
